<template>
  <b-modal
    id="bet-modal"
    ref="bet-setting-modal"
    hide-footer
    size="md"
    :title="'ตั้งค่าเดิมพัน '+title"
    @show="resetModal"
    @hidden="resetModal"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="ตั้งค่าให้"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="ชื่อใช้งาน"
          disabled
          v-model="accountUsername"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="หวย"
        label-for="input-market-title"
      >
        <b-form-input
          id="input-market-title"
          type="text"
          placeholder="หวย"
          disabled
          v-model="groupTitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="เดิมพัน"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="50%">เลข</th>
              <th width="50%">{{title}}</th>
            </tr>
            <tr class="bg-light">
              <th></th>
              <th><b-form-input size="sm" v-model="inputAll" :number="true"></b-form-input></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in marketOpenBets" :key="bet.code">
              <td>
                {{ bet.text }}
              </td>
              <td>
                <b-input-group size="sm" :append="refData[bet.code][data.bet.limit].toString()">
                  <b-form-input
                    size="sm"
                    v-model="input.betLimit[bet.code][data.bet.limit]"
                    :state="input.betLimit[bet.code][data.bet.limit] <= refData[bet.code][data.bet.limit]"
                    :number="true"
                    required>
                  </b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import {marketOpenBets} from '@/configs/market.config'
import AccountSettingService from "@/services/AccountSettingService"
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

const defaultInput = {}

export default {
  name: 'EditBetModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: [Object, String],
      default: null
    },
    refData: {
      type: Object
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    title() {
      if(!this.data)
        return ''

      return {
        'min': 'ขั้นต่ำ',
        'max': 'สูงสุด',
        'maxPerNumber': 'สูงสุดต่อเลข'
      }[this.data.bet.limit]
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: defaultInput,
      account: null,
      accountUsername: '',
      groupTitle: null,
      inputAll: ''
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      if(this.data) {
        const data = JSON.parse(JSON.stringify(this.data))
        this.account = data.account
        this.accountUsername = data.account.user.username
        this.input = data.bet

        const group = this.$store.state.marketGroups.find((g)=>{
          return g._id === data.bet.groupId
        })
        this.groupTitle = group?.groupTitle
      }
    },
    inputAll() {
      if(this.inputAll > 0) {
        this.marketOpenBets.forEach((bet)=>{
          this.input.betLimit[bet.code][this.data.bet.limit] = this.inputAll
        })
      }
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.input = defaultInput
      this.inputAll = ''
    },
    showModal() {
      this.$refs['bet-setting-modal'].show()
    },
    hideModal() {
      this.$refs['bet-setting-modal'].hide()
    },
    handleSubmit() {
      try {

        /**
         * ตรวจสอบตัวเลข
         */
        for (const bet of marketOpenBets) {
          if(this.input.betLimit[bet.code][this.data.bet.limit] > this.refData[bet.code][this.data.bet.limit]) {
            throw new Error('ค่าต้องน้อยกว่าของเอย่นต์')
          }
        }

        AccountSettingService.updateAccountSettingBet(this.input)
        .then((response)=>{
          if(response.success) {
            this.needReload = true
            this.hideModal()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw new Error(response.data)
          }
        })
        .catch((e)=>{
          cAlert({
          ...e,
            title: 'ไม่สำเร็จ!',
            text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
      }
      catch(e) {
        cAlert({
          ...e,
          title: 'ข้อมูลไม่ถูกต้อง!',
          text: e?.message || 'กรุณาตรวจสอบข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
